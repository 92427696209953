import React, {Component} from "react"
import BaseLayout from "../components/Layout/BaseLayout";
import {graphql} from "gatsby";
import TagSearchHome from "../components/Layout/TagSearchHome";
import {Link} from 'gatsby';
import {DISEASES_URL} from "../constants";
import ResponsiveContainer from "../components/ResponsiveContainer";

class HomePage extends Component {

  constructor(props){

    super(props);

    this.state= {
      show:false,
      diseases: null
    };

  }

  componentDidMount() {

    this.setState({
      show:true
    });
    const {data} = this.props;
  }


  render() {

    const {data} = this.props;

    const diseasePages = data.gravql.pages.length >= 1 && data.gravql.pages[1].children.filter((item)=>item.route === DISEASES_URL)[0].children;

    return (
        <BaseLayout data={data.gravql} show={this.state.show}>
          <div className="homePage symptom-tags-home__search">
            <div className="homePage__logo"/>
            <div className="homePage__payoff">
              <h1>
                <span>Complete en betrouwbare informatie over alle lysosomale stapelingsziekten</span>
              </h1>
            </div>
            <ResponsiveContainer>
              <TagSearchHome
                  searchUrl={"/lysosomale-stapelingsziekten/zoek-op-symptoom"}
                  diseasesUrl= {DISEASES_URL}
                  pages={diseasePages}
              />
            </ResponsiveContainer>
            <div className="homePage__searchLinks">
              <Link to={DISEASES_URL} className="diseases"><span /> Alle stapelingziekten A-Z </Link>
              <Link to="/lysosomale-stapelingsziekten/symptomen" className="symptoms"><span />Alle symptomen A-Z</Link>
              <Link to="/lysosomale-stapelingsziekten/visueel-zoeken" className="visual"><span />Visueel zoeken</Link>
            </div>
          </div>
        </BaseLayout>
    )
  }
}

export default HomePage;


export const query = graphql`
{
    currentBuildDate {
        currentDate
    }
    gravql {
        pages {     
   
            title
            visible
            header {
                title
                body_classes
            }
            children {
                route
                title
                header {
                    body_classes
                    title
                }    
                children {
                    route
                    title
                      children {
                        route
                        title
                    }      
                }                 
            }
            route
        }
        page(route: "/home") {
            title      
            content
            route

            imagesCropped(crop:true, width: "200", height:"200"){
                path
                filename
                url
                width
                height
            }
            metadata {
                name
                content
            }   
        }
    }
}
`;
